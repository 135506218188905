:root {
  --rmdp-primary-beige: #dcc6b7;
  --rmdp-secondary-beige: #dcc6b7;
  --rmdp-shadow-beige: #dcc6b7;
  /* optinal current date highlight */
  /* --rmdp-today-beige: #DCC6B7; */
  --rmdp-hover-beige: #dcc6b7;
  --rmdp-deselect-beige: #dcc6b7;
}
.rmdp-input {
  margin-top: 0.625rem;
  background-color: #212121;
  color: white;
}
.beige .rmdp-wrapper {
  border: 1px solid var(--rmdp-secondary-beige);
  box-shadow: 0 0 0.3125rem var(--rmdp-secondary-beige);
}

.beige .rmdp-panel-body li {
  background-color: var(--rmdp-primary-beige);
  box-shadow: 0 0 0.125rem var(--rmdp-secondary-beige);
}

.beige .rmdp-week-day {
  color: var(--rmdp-primary-beige);
}

.beige .rmdp-day.rmdp-deactive {
  color: var(--rmdp-secondary-beige);
}

.beige .rmdp-range {
  background-color: var(--rmdp-primary-beige);
  box-shadow: 0 0 0.1875rem var(--rmdp-shadow-beige);
}

.beige .rmdp-arrow {
  border: solid var(--rmdp-primary-beige);
  border-width: 0 2px 2px 0;
}

.beige .rmdp-arrow-container:hover {
  background-color: var(--rmdp-primary-beige);
  box-shadow: 0 0 0.1875rem var(--rmdp-secondary-beige);
}

.beige .rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--rmdp-primary-beige) !important;
}

.beige .rmdp-day.rmdp-today span {
  background-color: var(--rmdp-today-beige);
}

.beige .rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--rmdp-secondary-beige);
}

.beige .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary-beige);
  box-shadow: 0 0 0.1875rem var(--rmdp-shadow-beige);
}

.beige .rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: var(--rmdp-hover-beige) !important;
}

.beige .b-deselect {
  color: var(--rmdp-deselect-beige);
  background-color: white;
}

.beige .rmdp-action-button {
  color: var(--rmdp-primary-beige);
}

.beige .rmdp-button:not(.rmdp-action-button) {
  background-color: var(--rmdp-primary-beige);
}

.beige .rmdp-button:not(.rmdp-action-button):hover {
  background-color: var(--rmdp-deselect-beige);
}
